import React, { useEffect, useState, useContext } from 'react'
import { StripeProvider } from 'react-stripe-elements'
import { useTransition, animated } from 'react-spring'

import { client } from 'services/contentful'
import {
  Button,
  Container,
  Section,
  Stack,
  Box,
  ErrorBoundary,
  Heading,
  Body
} from 'components'
import { useMagic, useCartStore } from 'stores'
import CartItem from './CartItem'
import CouponInput from './CouponInput'
import Checkout from './Checkout'
import Price from './Price'
import { CartItem as CartItemType } from 'stores/useCartStore'

const calculatePrice = (items: CartItemType[]) => {
  if (items.length === 0) {
    return 0.0
  } else {
    return items
      .map(item => Number(item.price))
      .reduce((acc, val) => acc + val)
      .toFixed(2)
  }
}
export default function Cart() {
  const [checkout, setCheckout] = useState(false)
  const { cart } = useCartStore(state => ({ cart: state.cart }))

  const [activeCoupon, setActiveCoupon] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [items, setItems] = useState<CartItemType[]>([])
  const [price, setPrice] = useState(calculatePrice(items))
  const { isAuthenticated } = useMagic()

  useEffect(() => {
    if (cart.length > 0) {
      setIsLoading(true)
      client
        .getEntries({
          content_type: 'book',
          'sys.id[in]': cart.map(item => item.contentful_id).join()
        })
        .then((res: any) => {
          const books = res.items.map((item: any) => {
            return {
              contentful_id: item.sys.id,
              cover: res.includes.Asset.find(
                (asset: any) => asset.sys.id === item.fields.cover.sys.id
              ).fields.file.url,
              price: item.fields.price,
              title: item.fields.title
            }
          })
          setItems(books)
          setPrice(calculatePrice(books))
        })
      setIsLoading(false)
    }
  }, [cart])

  useEffect(() => {
    if (isAuthenticated && cart.length > 0) {
      setCheckout(true)
    }
    if (cart.length < 1) {
      setCheckout(false)
    }
  }, [cart])

  const closeCheckout = () => {
    setCheckout(false)
  }

  const transitions = useTransition(items, item => item.contentful_id, {
    from: { transform: 'translate3d(-100%,0,0)' },
    enter: { transform: 'translate3d(0%,0,0)' },
    leave: { transform: 'translate3d(-100%,0,0)' }
  })

  return (
    <ErrorBoundary>
      <Section
        minHeight="100vh"
        mt={4}
        alignItems="stretch"
        display="flex"
        py="5rem"
        data-testid="cart-page"
      >
        <Container
          maxWidth="lg"
          style={{
            display: checkout ? 'grid' : 'block',
            gridTemplateColumns: '2fr 1fr',
            gridColumnGap: '3rem'
          }}
        >
          <div>
            <Heading variant="md">Your Cart</Heading>
            {isLoading ? (
              <CartItem loading />
            ) : cart.length > 0 ? (
              transitions.map(({ item, props }) => (
                <animated.div style={props} key={item.contentful_id}>
                  <CartItem item={item} key={item.contentful_id} />
                </animated.div>
              ))
            ) : (
              <Body>Cart is empty</Body>
            )}
            <Stack
              gridTemplateColumns="repeat(2, 1fr)"
              justifyItems="start"
              alignItems="center"
            >
              {checkout || cart.length === 0 ? (
                <div />
              ) : (
                <Button onClick={() => setCheckout(true)}>Checkout</Button>
              )}

              <Box
                display="flex"
                width="100%"
                flexDirection="column"
                alignItems="flex-end"
              >
                <Price price={price} />
                {process.env.NODE_ENV !== 'test' && (
                  <CouponInput
                    price={price}
                    setActiveCoupon={setActiveCoupon}
                    setPrice={setPrice}
                  />
                )}
              </Box>
            </Stack>
          </div>
          {checkout && (
            <StripeProvider apiKey={process.env.GATSBY_STRIPE_KEY}>
              <Checkout
                closeCheckout={closeCheckout}
                activeCoupon={activeCoupon}
              />
            </StripeProvider>
          )}
        </Container>
      </Section>
    </ErrorBoundary>
  )
}
