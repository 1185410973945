import React, { useContext } from 'react'
import { FiX } from 'react-icons/fi'
import IconButton from '@material-ui/core/IconButton'

import { Skeleton, Body, Stack } from 'components'
import Component, { Cover } from './style'
import { RequireAtLeastOne } from 'utils'
import { useCartStore } from 'stores'
import { CartItem as CartItemType } from 'stores/useCartStore'

type Props = {
  item?: CartItemType
  loading?: boolean
}

export default function CartItem({
  item,
  loading
}: RequireAtLeastOne<Props, 'item' | 'loading'>) {
  if (loading) {
    return (
      <Component>
        <Stack
          gridTemplateColumns="repeat(2, 50%)"
          width="100%"
          alignItems="center"
        >
          <Skeleton height="12rem" width="10rem" borderRadius="10px" />
          <Skeleton width="30ch" height="1rem" />
        </Stack>
        <div>
          <Skeleton width="3ch" height="1rem" />
        </div>
        <div />
      </Component>
    )
  }
  if (item) {
    return (
      <Component data-testid="cart-item">
        <Stack
          gridTemplateColumns="repeat(2, 50%)"
          width="100%"
          alignItems={'center'}
        >
          <Cover src={item.cover} />
          <Body mb={0}>{item.title}</Body>
        </Stack>
        <div>
          <Body fontWeight={'bold'} mb={0}>
            ${item.price}
          </Body>
        </div>

        <IconButton
          size="small"
          data-testid="remove-item-btn"
          onClick={() =>
            useCartStore.getState().removeFromCart(item.contentful_id)
          }
        >
          <FiX />
        </IconButton>
      </Component>
    )
  } else {
    return <div />
  }
}
